import { PUBLIC_BRANCH as environment, PUBLIC_SENTRY_DSN as dsn } from '$env/static/public'
import { version as release } from '$app/environment'
import * as Sentry from '@sentry/svelte'

if (environment !== 'local') {
  Sentry.init({
    dsn,
    environment,
    release,
    tracesSampleRate: 1.0

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0
  })
}

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event, status, message }) {
  const errorId = crypto.randomUUID()

  if (environment !== 'local') {
    // example integration with https://sentry.io/
    Sentry.captureException(error, {
      extra: { event, errorId, status }
    })
  }

  return {
    message,
    errorId
  } as Error & { errorId: string }
}
